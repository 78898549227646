<template>
	<div v-if="authed">
        <v-data-table :headers="headers" :items="items" item-key="_id"
			:options.sync="options" :server-items-length="totalItems"
			:loading="loading" loading-text="加载中...">
            <template v-slot:top>
				<v-row class="pt-2">
					<v-col cols="12" md="2">
						<v-btn-toggle mandatory dense v-model="filter" @change="fetchData">
							<v-btn>全部</v-btn>
							<v-btn>待确认</v-btn>
							<v-btn>已付款</v-btn>
						</v-btn-toggle>
					</v-col>
					<v-col cols="12" md="2">
						<v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="10"
							transition="scale-transition" offset-y min-width="290px">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field v-model="pdate1" label="日期起" readonly v-bind="attrs"
									v-on="on" dense hide-details clearable></v-text-field>
							</template>
							<v-date-picker v-model="pdate1" @input="menu1 = false" no-title scrollable></v-date-picker>
						</v-menu>
					</v-col>
					<v-col cols="12" md="1">
						<v-select label="时间起" v-model="ptime1" :items="['00:00','09:00','10:00','16:00']" dense hide-details/>
					</v-col>
					<v-col cols="12" md="2">
						<v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="10"
							transition="scale-transition" offset-y min-width="290px">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field v-model="pdate2" label="日期止" readonly v-bind="attrs"
									v-on="on" dense hide-details clearable></v-text-field>
							</template>
							<v-date-picker v-model="pdate2" @input="menu2 = false" no-title scrollable></v-date-picker>
						</v-menu>
					</v-col>
					<v-col cols="12" md="1">
						<v-select label="时间止" v-model="ptime2" :items="['08:59','09:59','15:59','23:59']" dense hide-details/>
					</v-col>
					<v-col cols="12" md="2">
						<v-text-field placeholder="姓名" v-model="pname" dense clearable hide-details/>
					</v-col>
					<v-col cols="12" md="2" class="pt-2">
						<v-btn color="primary" @click="fetchData" class="mx-1">查询</v-btn>
						<v-btn color="primary" @click="exportXLS" class="mx-1">导出</v-btn>
					</v-col>
				</v-row>
                <v-divider/>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon class="mr-2" @click.stop="editItem(item)">mdi-pencil</v-icon>
            </template>
            <template v-slot:item.progress.order="{ item }">
                {{formatTime(item.progress.order)}}
            </template>
            <template v-slot:item.progress.updateAmount="{ item }">
                {{formatTime(item.progress.updateAmount)}}
            </template>
            <template v-slot:item.progress.payment="{ item }">
                {{formatTime(item.progress.payment)}}
            </template>
			<template v-slot:item.insurance="{ item }">
				{{getInsurance(item)}}
			</template>
            <template v-slot:item.idcard="{ item }">
                {{getMaskedIdcard(item)}}
            </template>
            <template v-slot:item.opts="{ item }">
                {{getOptsStr(item)}}
            </template>
			<template v-if="supv" v-slot:item.payment.way="props">
				<v-edit-dialog large :return-value.sync="props.item.payment.way"
					save-text="保存" cancel-text="取消" @save="saveItem('payment.way', props)">
					{{props.item.payment.way}}
					<template v-slot:input>
						<v-combobox :items="payways" v-model="props.item.payment.way"/>
					</template>
				</v-edit-dialog>
			</template>
			<template v-slot:item.payment.discount="props">
				<v-edit-dialog v-if="props.item.payment.discount" @open="fetchVoucher(props.item.payment.voucher)">
					{{props.item.payment.discount}}
					<template v-slot:input>
						<v-list width="350" dense>
							<v-list-item v-for="(v, k) in voucher" :key="k" dense>
								<v-list-item-title>{{k}}</v-list-item-title>
								<v-list-item-subtitle class="text-right">{{v}}</v-list-item-subtitle>
							</v-list-item>
						</v-list>
					</template>
				</v-edit-dialog>
			</template>
        </v-data-table>
        <v-dialog v-model="dialog" persistent max-width="450">
			<v-card>
				<v-card-title>付款状态</v-card-title>
				<template v-if="payway==='微信'">
					<v-card-text>
						<v-form ref="paymentform" :readonly="locked">
							<v-container>
								<v-row>
									<v-col cols="12" sm="4"><v-text-field label="姓名" v-model="currentItem.pii.username" readonly dense/></v-col>
									<v-col cols="12" sm="4"><v-text-field label="应付" v-model.number="amount" :rules="numberRules" dense/></v-col>
									<v-col cols="12" sm="4"><v-text-field label="微信实付" v-model="actualPayment" readonly dense/></v-col>
								</v-row>
								<v-text-field label="备注" v-model="paymentComment" dense />
							</v-container>
						</v-form>
					</v-card-text>
					<v-card-actions>
						<v-spacer/>
						<v-btn @click="dialog=false">取消</v-btn>
						<v-btn color="primary" @click="saveWXPay" :loading="loadingsave" :disabled="loadingsave||locked">保存</v-btn>
					</v-card-actions>
				</template>
				<template v-else>
					<v-card-text>
						<v-form ref="paymentform" :readonly="locked">
							<v-container>
								<v-row>
									<v-col cols="12" sm="4"><v-text-field label="姓名" v-model="currentItem.pii.username" readonly dense/></v-col>
									<v-col cols="12" sm="4"><v-text-field label="应付" v-model="amount" readonly dense/></v-col>
									<v-col cols="12" sm="4"><v-text-field label="实付" v-model.number="actualPayment" :rules="numberRules" dense/></v-col>
								</v-row>
								<v-text-field label="备注" v-model="paymentComment" dense />
							</v-container>
						</v-form>
					</v-card-text>
					<v-card-actions>
						<v-spacer/>
						<v-btn @click="dialog=false">取消</v-btn>
						<v-btn color="primary" @click="save" :loading="loadingsave" :disabled="loadingsave||locked">保存</v-btn>
					</v-card-actions>
				</template>
			</v-card>
        </v-dialog>
	    <v-snackbar color="error" v-model="snackbar">
			{{errmsg}}
			<template v-slot:action="{ attrs }">
				<v-btn outlined small v-bind="attrs" @click="snackbar = false">关闭</v-btn>
			</template>
		</v-snackbar>
    </div>
    <div v-else>
        无此权限
    </div>
</template>

<script>
	import XLSX from 'xlsx-js-style'
	import {formatTime, formatDate, nowOffsetHours} from '../utils'

    export default {
        name: 'Order',
        data() {
            return {
                authed: false,
				supv: false,
                headers: [
                    {text:'', value:'actions', width:40, sortable: false},
                    {text:'姓名', value:'pii.username', width:120},
                    {text:'性别', value:'pii.gender', width:80},
                    {text:'付款方式', value:'payment.way', width:100},
//                    {text:'微信名', value:'userInfo.nickName', width:180},
                    {text:'支付方', value:'insurance', width:150},
                    {text:'下单时间', value:'progress.order', width:180},
                    {text:'修改应付时间', value:'progress.updateAmount', width:180},
                    {text:'付款确认时间', value:'progress.payment', width:180},
                    {text:'原价', value:'price', width:100},
                    {text:'券减', value:'payment.discount', width:100},
                    {text:'应付', value:'payment.amount', width:100},
                    {text:'实付', value:'payment.actual', width:100},
                    {text:'优惠选项', value:'payment.coupontext', width:120},
                    {text:'付款备注', value:'payment.comment', width:200},
                    {text:'健康顾问', value:'consultantName', width:100},
                    {text:'套餐内容', value:'opts', sortable: false, width:240},
                    {text:'已选专家', value:'doctorName', width:100},
                    {text:'证件类型', value:'pii.idtype', width:100},
                    {text:'证件号', value:'idcard', width:120},
                    {text:'电话', value:'pii.phone', width:120},
                    {text:'订单号', value:'_id', width:100},
                    {text:'订单类型', value:'orderType', width:120},
                    {text:'诊所', value:'region', width:100},
                ],
                items: [],
                loading: false,
                dialog: false,
                loadingsave: false,
				locked: false,
                currentItem: {pii:{}},
				numberRules: [v => (/^[0-9]+(.[0-9]*)?$/g).test(v) || '只允许数字'],
				payway:'标准',
				amount: '',
				actualPayment: '',
				paymentComment: '',
				pdate1: '',
				ptime1: '',
				menu1: false,
				pdate2: '',
				ptime2: '',
				menu2: false,
				filter: 0,
				options: {},
				totalItems: 0,
				snackbar: false,
				errmsg: '',
				voucher: {},
				payways: ['微信','快钱扫码','统一结算','大众点评','其他'],
				pname: '',
            }
        },
		watch: {
			options: {
				handler () {
					this.fetchData()
				},
				deep: true,
			},
		},
        mounted() {
			this.supv = this.$hasPrivilege('客户付款确认');
			this.authed = this.supv || this.$hasPrivilege('健康顾问');
			if (!this.authed) return;
            this.formatTime = formatTime;
			this.ptime1 = '09:00';
			this.ptime2 = '15:59';
            this.fetchData();
		},
        methods: {
            async fetchData() {
				const filter = this.getFilter();
				const db = this.$tcbapp.database();
				const _ = db.command;
				this.loading = true;
				try {
                    const countRes = await db.collection('wp2order').where(filter).count();
					this.totalItems = countRes.total;
					const { sortBy, sortDesc, page, itemsPerPage } = this.options;
					const res = await db.collection('wp2order').where(filter).orderBy(sortBy[0]||'_', sortDesc[0] ? 'desc' : 'asc')
						.skip((page-1) * itemsPerPage).limit(itemsPerPage)
						.get();
					this.items = res.data;
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
            },
			getFilter() {
				const db = this.$tcbapp.database();
				const _ = db.command;
				let filter = [{payment:_.exists(true)}];
				if (this.pdate1 && this.pdate2) {
					const t1 = new Date(this.pdate1 + 'T' + this.ptime1 + ':00+08:00');
					const t2 = new Date(this.pdate2 + 'T' + this.ptime2 + ':59+08:00');
					if (this.filter == 2) {
						filter.push({'progress.payment':_.gte(t1).lte(t2)});
					} else {
						filter.push({'progress.order':_.gte(t1).lte(t2)});
					}
				}
				if (this.$hasPrivilege('客户付款确认')) {
					//admin也有此权限
				} else if (this.$hasPrivilege('健康顾问')) {
					const u = this.$getCurrentUser();
					filter.push({consultantId: u.consultantId});
				}
				if (this.pname) {
					filter.push({'pii.username':this.pname});
				}
				filter.push({hide:_.neq(true)});
				const filters = [
					[],
					[{"payment.actual": _.exists(false)}],
					[{"payment.actual": _.exists(true)}],
				];
				const f = filter.concat(filters[this.filter]);
				return _.and(f);
			},
			async fetchVoucher(voucher) {
				this.voucher = this.$options.data().voucher;
				const db = this.$tcbapp.database();
				try {
					const res = await db.collection('wp2voucher').doc(voucher).get();
					const va = res.data[0];
					for (let k in va) {
						if (Object.prototype.toString.call(va[k]) === '[object Date]') {
							va[k] = formatTime(va[k]);
						}
					}
					this.voucher = res.data[0];
				} catch(err) {
					console.error(err);
				}
			},
			async exportXLS() {
				const MAX_LIMIT = 100;
				const filter = this.getFilter();
				const db = this.$tcbapp.database();
				const countResult = await db.collection('wp2order').where(filter).count();
				const total = countResult.total;
				const batchTimes = Math.ceil(total / MAX_LIMIT);
				const tasks = [];
				for (let i = 0; i < batchTimes; i++) {
					const promise = db.collection('wp2order').where(filter).skip(i * MAX_LIMIT).limit(MAX_LIMIT).get();
					tasks.push(promise);
				}
				const res = (await Promise.all(tasks)).reduce((acc, cur) => {
					return {
						data: acc.data.concat(cur.data),
						errMsg: acc.errMsg,
					}
				});
				const header = [[
					'订单号',
					'诊所',
					'姓名',
					'性别',
					'证件名称',
					'证件号码',
					'电话号码',
					'检查项目',
					'所选专家',
					'健康顾问',
					'原价',
					'券减',
					'应付',
					'实付',
					'支付方式',
					'付款备注',
					'下单时间',
					'修改应付时间',
					'付款确认时间',
				]];
				const data = res.data.map(x => [
					x._id,
					x.region,
					x.pii.username,
					x.pii.gender,
					x.pii.idtype,
					x.pii.idcard,
					x.pii.phone,
					this.getOptsStr(x),
					x.doctorName || '',
					x.consultantName,
					x.price || '',
					x.payment.discount || '',
					x.payment.amount || '',
					x.payment.actual || '',
					x.payment.way || '',
					x.payment.comment || '',
					formatTime(x.progress.order),
					formatTime(x.progress.updateAmount),
					formatTime(x.progress.payment),
				]);
				const t = (this.pdate1 && this.pdate2) ? this.pdate1 + '~' + this.pdate2 : 'WP';
				const filename = "WP付款导出.xlsx";
				const ws_name = t;
				const wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(header.concat(data), {dateNF: 'YYYY-MM-DD HH:mm:ss'});
				XLSX.utils.book_append_sheet(wb, ws, ws_name);
				XLSX.writeFile(wb, filename);
			},
			getInsurance(item) {
				return item.payment.insurance && item.payment.insurer ? `${item.payment.insurer} - ${item.payment.insurance}` : '自费';
			},
			getMaskedIdcard(item) {
				return item.pii.idcard;
				//return item.pii.idcard.replace(/^(.{3})(?:\d+)(.{3})$/,  "\$1****\$2");
			},
            getOptsStr(item) {
				return item.opts.GIE + '/' + item.opts.ANES + '/' + item.opts.PRO;
            },
            editItem(item) {
				if (item.payment.actual !== undefined && item.payment.actual !== null) {
					this.errmsg = '此订单已付款';
					this.snackbar = true;
					return;
				}
                this.currentItem = item;
				this.payway = item.payment.way;
				this.amount = item.payment.amount;
				this.actualPayment = item.payment.actual;
				this.paymentComment = item.payment.comment;
				this.locked = item.payment.actual !== undefined && item.payment.actual !== null;
                this.dialog = true;
            },
            async save() {
				if (!this.$refs.paymentform.validate()) return;
				try {
					this.loadingsave = true;
					const id = this.currentItem._id;
					await this.$tcbapp.callFunction({
						name:"wp2mp",
						data:{
							funcname:'savePayment',
							data: {
								id,
								actual: parseFloat(this.actualPayment),
								comment: this.paymentComment,
							}
						}
					});
					await this.fetchData();
					this.loadingsave = false;
					this.dialog = false;
				} catch(err) {
					console.error(err);
				}
			},
            async saveWXPay() {
				if (!this.$refs.paymentform.validate()) return;
				try {
					this.loadingsave = true;
					const id = this.currentItem._id;
					await this.$tcbapp.callFunction({
						name:"wp2mp",
						data:{
							funcname:'updateAmount',
							data: {
								id,
								amount: parseFloat(this.amount),
								comment: this.paymentComment,
							}
						}
					});
					await this.fetchData();
					this.loadingsave = false;
					this.dialog = false;
				} catch(err) {
					console.error(err);
				}
			},
			async saveItem(key, props) {
				await this.$tcbapp.callFunction({name:"wp2mp", data:{funcname:'editInfo',
					data:{
						id: props.item._id,
						key,
						value: props.value
					}
				}});
			},
        }
    }
</script>